<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Pengajuan Proposal
      <!-- <router-link slot="right" to="/mahasiswa/create" class="button is-primary">
        Tambah
      </router-link> -->
    </hero-bar>
    <section class="section is-main-section">

      <div class="card has-table has-mobile-sort-spaced">
        <header class="card-header">
          <p class="card-header-title">
            <b-icon icon="account-multiple" custom-size="default" />
            Pengajuan Proposal
          </p>
          <b-field class="card-header-icon">
              <b-input placeholder="Search..."
                  type="search"
                  size="is-small"
                  icon="magnify"
                  v-model="searchWord"
                  @input="search(searchWord)">
              </b-input>
          </b-field>
        </header>
        <div class="card-content">
          <modal-box
            :is-active="isModalActive"
            :trash-object-name="trashObjectName"
            @confirm="trashConfirm"
            @cancel="trashCancel"
          />
          <b-table
            :checked-rows.sync="checkedRows"
            :loading="isLoading"
            :paginated="paginated"
            :per-page="perPage"
            :striped="true"
            :hoverable="true"
            default-sort="name"
            :data="data"
          >
            <b-table-column label="Nama" field="name" sortable v-slot="props">
              {{ props.row.mahasiswa }}
            </b-table-column>
            <b-table-column label="NIM" field="nim" sortable v-slot="props">
              {{ props.row.nim }}
            </b-table-column>
            <b-table-column label="Tahun Akademik" field="tahun akademik" sortable v-slot="props">
              {{ props.row.tahun }}
            </b-table-column>
            <b-table-column label="Status" field="status" sortable v-slot="props">
              {{ props.row.status }}
            </b-table-column>
            <b-table-column label="Pembimbing1" field="Pembimbing1" sortable v-slot="props">
              {{ props.row.pembimbing1 }}
            </b-table-column>
            <b-table-column label="Pembimbing2" field="Pembimbing2" sortable v-slot="props">
              {{ props.row.pembimbing2 }}
            </b-table-column>
            <b-table-column label="Judul" field="judul" sortable v-slot="props">
              {{ props.row.judul }}
            </b-table-column>
            <b-table-column label="Proposal" field="proposal" sortable v-slot="props">
              <a :href="props.row.file" target="_blank">
              unduh
              </a>
            </b-table-column>
            <b-table-column label="STUG" field="stug" sortable v-slot="props">
              <a :href="props.row.stug" target="_blank" v-if="props.row.stug">
              unduh
              </a>
            </b-table-column>
            <!-- <b-table-column label="Email" field="email" sortable v-slot="props">
              {{ props.row.email }}
            </b-table-column>
            <b-table-column label="Kontak" field="phone" sortable v-slot="props">
              {{ props.row.phone }}
            </b-table-column> -->
            <!-- <b-table-column label="Dibuat" v-slot="props">
              <small class="has-text-grey is-abbr-like" :title="props.row.created_at">{{ props.row.created_at.substr(0,10) }}</small>
            </b-table-column> -->
            <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
              <div class="buttons is-right">
                <router-link :to="{name:'pengajuan_proposal.edit', params: {id: props.row.id}}" class="button is-small is-primary">
                  <b-icon icon="account-edit" size="is-small"/>
                </router-link>
                <button class="button is-small is-danger" type="button" @click.prevent="trashModal(props.row)">
                  <b-icon icon="trash-can" size="is-small"/>
                </button>
              </div>
            </b-table-column>

            <section slot="empty" class="section">
              <div class="content has-text-grey has-text-centered">
                <template v-if="isLoading">
                  <p>
                    <b-icon icon="dots-horizontal" size="is-large" />
                  </p>
                  <p>Fetching data...</p>
                </template>
                <template v-else>
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>Nothing's here&hellip;</p>
                </template>
              </div>
            </section>
          </b-table>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import axios from 'axios'
import ModalBox from '@/components/ModalBox'

export default {
  name: 'Tables',
  data () {
    return {
      isModalActive: false,
      trashObject: null,
      oriData: [],
      data: [],
      searchWord: null,
      isLoading: false,
      paginated: false,
      perPage: 10,
      checkedRows: []
    }
  },
  components: {
    HeroBar,
    TitleBar,
    ModalBox
  },
  computed: {
    titleStack () {
      return ['Home', 'Pengajuan Proposal']
    },
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }

      return null
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.isLoading = true
      axios
        .get('/proposal')
        .then((r) => {
          this.isLoading = false
          if (r.data && r.data.data) {
            if (r.data.data.length > this.perPage) {
              this.paginated = true
            }
            this.data = r.data.data
            this.oriData = r.data.data
          }
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    },
    search (word) {
      if (word === null || word === '') {
        this.getData()
        return false
      }

      console.log(`search for ${word}`)
      const result = this.oriData.filter(obj => {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(obj)) {
          // console.log(`${word} in ${value} ${String(value).toLowerCase().indexOf(word)}`)
          if (String(value).toLowerCase().indexOf(word.toLowerCase()) >= 0) {
            return true
          }
        }
      })

      this.data = result
    },
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false

      axios
        .delete(`/proposal/${this.trashObject.id}`)
        .then((r) => {
          console.log(r.data)
          if (r.data.status === 'ok') {
            this.$buefy.toast.open({
              message: r.data.message,
              queue: false
            })
            this.$router.go()
          } else {
            this.$buefy.toast.open({
              message: r.data.message,
              type: 'is-danger',
              queue: false
            })
          }
        })
        .catch((e) => {
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger',
            queue: false
          })
        })
    },
    trashCancel () {
      this.isModalActive = false
    }
  }
}
</script>
